<script setup>
import { inject, ref } from 'vue';
import { useRoute } from 'vue-router';

const $require = inject('$require');
const route = new useRoute();
let data = ref('');
let getList = () => {
  let id = route.query.id;
  $require.HttpPost('/h5/o_balance/balanceBill', {
    log_id: id
  }).then((res) => {
    if (res.data && res.data.list) {
      data.value = res.data.list[0];
    }
  });
};
getList();
</script>
<template>
  <div class="detail-box">
    <div class="detail" v-if="data">
      <div class="count">
        <img v-if="data.scene == 10" src="@/assets/image/my/zr.png" alt />
        <img v-else src="@/assets/image/my/zc.png" alt />
        <span v-if="data.scene == 10">商户转入</span>
        <span v-else-if="data.scene == 20">抵扣</span>
        <span v-else-if="data.scene == 30">提现</span>
        <span v-else>{{ data.scene }}</span>

        <span v-if="data.money > 0">+{{ data.money }}</span>
        <span v-else>{{ data.money }}</span>
      </div>
      <div class="list">
        <div class="item">
          <span>类型</span>
          <span v-if="data.scene == 10">收入</span>
          <span v-else>支出</span>
        </div>
        <div class="item">
          <span>时间</span>
          <span>{{ data.create_at }}</span>
        </div>
        <div class="item">
          <span>余额</span>
          <span>￥{{ data.balance_money }}</span>
        </div>
        <div class="item">
          <span>描述</span>
          <van-notice-bar class="describe" background="white" style="width: 100%; padding: 0" :text="data.describe" />
          <!-- <span>{{data.describe}}</span> -->
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.detail-box {
  height: 100vh;
  padding: 20px 24px 0 24px;
  box-sizing: border-box;
  background: #f9f9f9;

  .detail {
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 50px 0 32px 0;
    box-sizing: border-box;

    .count {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid #efefef;
      padding-bottom: 50px;
      box-sizing: border-box;

      span {
        &:nth-child(2) {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #000000;
          margin: 19px 0 50px 0;
        }

        &:nth-child(3) {
          font-size: 60px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #000000;
        }
      }

      img {
        width: 80px;
        height: 80px;
      }
    }

    // 11111
    .list {
      margin-top: 50px;

      .item {
        display: flex;
        align-items: center;
        padding-left: 50px;
        box-sizing: border-box;
        margin-bottom: 30px;

        span {
          font-size: 28px;
          font-family: PingFang SC;
          font-weight: 500;

          &:nth-child(1) {
            color: #999999;
            width: 150px;
          }

          &:nth-child(2) {
            color: #000000;
          }
        }

        .describe {
          flex-shrink: 0;
          flex: 1;
        }
      }

      // .describe{
      //   span{
      //     &:nth-child(2){
      //       font-size: 12px;
      //       white-space: nowrap;
      //       ov
      //     }
      //   }
      // }
    }
  }
}
</style>
